<template>
  <section class="new_css">
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0"
        :span="24"
        class="organizetoolbar"
        style="padding-bottom: 0px"
      >
        <el-form
          :inline="true"
          ref="selectForm"
          :model="selectForm"
          class="select_form"
        >
          <el-form-item label="姓名:">
            <el-input
              v-model="selectForm.userName"
              placeholder="姓名"
            ></el-input>
          </el-form-item>
          <toolbar
            :buttonList="buttonList"
            @callFunction="callFunction"
            :buttonListmsg="buttonListmsg"
          ></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="calc-height">
      <!--列表-->
      <el-table
        :data="users"
        highlight-current-row
        height="100%"
     
        @current-change="selectCurrentRow"
        class="new_table"
      >
        <el-table-column prop="UserName" label="名称"> </el-table-column>
        <el-table-column
          label="身份证号"
          prop="IDCard"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="银行名称"
          prop="BankCardName"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="银行卡号"
          prop="BankCardCD"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="岗位工资" prop="WorkSalary"></el-table-column>
        <el-table-column label="职务工资" prop="PostSalary"></el-table-column>
        <el-table-column label="工资类型" prop="SalaryType"></el-table-column>
        <el-table-column label="是否有年功" prop="IsYear"></el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper,total"
      :page-count="total"
      :total="totaldata"
    >
    </el-pagination>
    <el-dialog
      :title="operation ? '新增' : '编辑'"
      :visible.sync="SakaryVisible"
      v-model="SakaryVisible"
      :close-on-click-modal="false"
    >
      <el-form
        :rules="SakaryRules"
        ref="SakaryForm"
        :model="SakaryForm"
        class="demo-form-inline"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="姓名:"
              prop="UserName"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                type="text"
                :disabled="true"
                v-model="SakaryForm.UserName"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="审核状态:"
              prop="State"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-select
                v-model="SakaryForm.State"
                placeholder="审核状态"
                style="width: 100%"
              >
                <el-option label="同意" value="同意"></el-option>
                <el-option label="退回" value="退回"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="银行名称:" prop="BankCardName">
              <el-input
                type="text"
                v-model="SakaryForm.BankCardName"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="银行卡号:" prop="BankCardCD">
              <el-input
                type="text"
                v-model="SakaryForm.BankCardCD"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="是否有年功:"
              prop="IsYear"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-select
                v-model="SakaryForm.IsYear"
                placeholder="是否有年功"
                style="width: 100%"
                :disabled="true"
              >
                <el-option label="是" value="是"></el-option>
                <el-option label="否" value="否"></el-option>
                <el-option label="超龄年功" value="超龄年功"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="超龄年功工资:" prop="OverAgeUserAvailable">
              <el-input
                type="text"
                v-model="SakaryForm.OverAgeUserAvailable"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="岗位工资:" prop="WorkSalary">
              <el-input
                type="text"
                v-model="SakaryForm.WorkSalary"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="职务工资:" prop="PostSalary">
              <el-select
                v-model="SakaryForm.PostSalary"
                @change="PositionModel($event)"
                placeholder="职务工资"
                style="width: 100%"
                :disabled="true"
              >
                <el-option
                  v-for="item in PositionLists"
                  :key="item.Id"
                  :label="item.LeaveName"
                  :value="item.LeaveName"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="工资类型:" prop="SalaryType">
              <el-select
                v-model="SakaryForm.SalaryType"
                @change="SalaryTypeModel($event)"
                placeholder="工资类型"
                style="width: 100%"
                :disabled="true"
              >
                <el-option
                  v-for="item in SalaryTypeLists"
                  :key="item.Id"
                  :label="item.Type"
                  :value="item.Type"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日保健费:" prop="CareDaySalary">
              <el-select
                v-model="SakaryForm.CareDaySalary"
                placeholder="日保健费"
                style="width: 100%"
                :disabled="true"
              >
                <el-option label="0" value="0"></el-option>
                <el-option label="5" value="5"></el-option>
                <el-option label="10" value="10"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="日工资单价:" prop="DaySalary">
              <el-input
                type="text"
                v-model="SakaryForm.DaySalary"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="特岗补贴:" prop="SpecialSalary">
              <el-input
                type="text"
                v-model="SakaryForm.SpecialSalary"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="伙食单价:" prop="EateDaySalary">
              <el-input
                type="text"
                v-model="SakaryForm.EateDaySalary"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="驻外补贴:" prop="OutsideSalary">
              <el-input
                type="text"
                v-model="SakaryForm.OutsideSalary"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="车辆补贴:" prop="BusMoney">
              <el-input
                type="text"
                v-model="SakaryForm.BusMoney"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="值班日保健费:" prop="OndutyCareSalary">
              <el-input
                type="text"
                v-model="SakaryForm.OndutyCareSalary"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="爱心基金:" prop="LoveMoney">
              <el-input
                type="text"
                v-model="SakaryForm.LoveMoney"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工人系数:" prop="WorkerMod">
              <el-input
                type="text"
                v-model="SakaryForm.WorkerMod"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="租房补贴:" prop="HouseSalary">
              <el-input
                type="text"
                v-model="SakaryForm.HouseSalary"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="绩效工资:" prop="PerfSalary">
              <el-input
                type="text"
                v-model="SakaryForm.PerfSalary"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <el-form-item label="学历补贴:" prop="EducationSub">
                    <el-input type="text"
                              v-model="SakaryForm.EducationSub"
                              :disabled="true">
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="职能优化绩效:" prop="PositionPerformance">
                    <el-input type="text"
                              v-model="SakaryForm.PositionPerformance"
                              :disabled="true">
                    </el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="备注:"
              prop="Remark"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input type="textarea" v-model="SakaryForm.Remark"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" style="text-align: center">
         
              <el-button type="primary" v-on:click="onSubmit('SakaryForm')"
                >提交</el-button
              >
         
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="addUserVisible"
      v-model="addUserVisible"
      width="75%"
      center
      append-to-body
    >
      <UserChoose
        :data="choosedusers"
        :all="true"
        :single="false"
        @callback="usercallFunction"
      ></UserChoose>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import {
  GetSubmitInfoPageList,
  UpdateSalaryBanksState,
  getInfoList,
  GetUserDis,
} from "../../api/hr";
import UserChoose from "../../components/UserChoose";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar, UserChoose },
  data() {
    return {
      searchVal: "",
      selectForm: {
        userName: "",
      },
      PositionLists: [],
      SalaryTypeLists: [],
      addUserVisible: false,
      choosedusers: [],
      loading: true,
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      SakaryVisible: false,
      buttonListmsg: "",
      users: [],
      operation: false, // true:新增, false:编辑
      buttonList: [],
      SakaryForm: {
        UserId: "",
        UserName: "",
        BankCardName: "",
        BankCardCD: null,
        IsYear: null,
        OverAgeUserAvailable: 0,
        WorkSalary: null,
        PostSalary: null,
        PostSalaryName: null,
        SalaryType: null,
        SalaryTypeId: null,
        CareDaySalary: 0,
        DaySalary: 0,
        SpecialSalary: 0,
        EateDaySalary: 0,
        OutsideSalary: 0,
        BusMoney: 0,
        OndutyCareSalary: 0,
        LoveMoney: 0,
        WorkerMod: 0,
        HouseSalary: 0,
          PerfSalary: 0,
          EducationSub: 0,
          PositionPerformance:0,
        Remark: "",
        PostSalaryId: "",
      },
      SakaryRules: {
        Name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        Type: [{ required: true, message: "请选择类型", trigger: "blur" }],
      },
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    inputMoney(name) {
      if (!validate.validateIsMoney(this.SakaryForm[name])) {
        this.$message({
          message: "请输入金额",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm[name] = null;
        return;
      }
    },
    chooseUser() {
      this.addUserVisible = true;
    },
    usercallFunction(newdata) {
      if (newdata.length > 1) {
        this.$message({
          message: "不允许选择多人",
          type: "error",
        });
        return;
      }
      console.log(newdata);

      this.SakaryForm.UserId = newdata[0].Id;
      this.SakaryForm.UserName = newdata[0].UserNameClaim;
      this.addUserVisible = false;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        userName: this.selectForm.userName,
      };
      console.log(para);
      GetSubmitInfoPageList(para).then((res) => {
        console.log(res);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      getInfoList().then((res) => {
        this.SalaryTypeLists = res.data.response;
      });
      GetUserDis().then((res) => {
        this.PositionLists = res.data.response;
      });
      this.SakaryForm = Object.assign({}, row);
      (this.operation = false), (this.SakaryVisible = true);
    },

    PositionModel(id) {
      var obj = {};
      obj = this.PositionLists.find((item) => {
        //model就是上面的数据源

        return item.LeaveName === id; //筛选出匹配数据
      });
      this.SakaryForm.PostSalaryId = obj.Id;
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          let para = Object.assign({}, this.SakaryForm);
          if (valid) {
            UpdateSalaryBanksState(para).then((res) => {
              if (res.data.success) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                  duration: 5000,
                });
                this.SakaryVisible = false;
                this.getData(false);
              } else {
                this.$message({
                  message: "修改失败",
                  type: "error",
                  duration: 5000,
                });
              }
            });
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
            return false;
          }
        });
      });
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
</style>

